import { Card, Tab, Tabs } from 'react-bootstrap'
import { useEffect, useState } from 'react'

import PlaybackContainer from '../PlaybackContainer'
import RecordContainer from '../RecordContainer'
import Playback from '@mui/icons-material/GraphicEqRounded'
import Record from '@mui/icons-material/AlbumRounded'
import { TabKey, TabKeyState } from '../../types/state/TabKeyState'
import { LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import { CloudContext, ConnectionState } from '../../services/LicenseService'
import { Namespace, PlaybackState, PlaybackStateFile, RecordingState } from '../../api/BrokerApi/types'
import { AuthenticatedUser } from '../../api/CloudApi/types'
import { ProductAnalyticsTracker } from '../../utils/ProductAnalytics'
import { isIframe } from '../../utils/CloudDetails'
import ScreenSize, { BootstrapBreakpoint } from '../../utils/ScreenSize'

interface MainCardProps {
    listFilesFunction: () => Promise<Array<PlaybackStateFile>>
    playbackState: PlaybackState | undefined
    recordingState: RecordingState | undefined
    connectionState: ConnectionState
    refreshPanels: Function
    tabKeyState: TabKeyState
    setLicenseFunction: (license: LicenseInfo) => void
    isLicensed: boolean
    isCloudBroker: boolean
    productAnalyticsTracker: ProductAnalyticsTracker
}

const DEFAULT_TAB_STYLE = 'align-bottom'
const TAB_ACTIVE_COLOR = 'remotive-dark-color'
const TAB_INACTIVE_COLOR = 'remotive-primary-50-color'

function MainCard(props: MainCardProps) {
    // State for record container
    const [currentStepperIndex, setCurrentStepperIndex] = useState<number>(0)
    const [selectedNamespaces, setSelectedNamespaces] = useState<Array<Namespace>>([])
    const [selectedFilename, setSelectedFilename] = useState<string>('')
    const [isPlaybackBarSticky, setIsPlaybackBarSticky] = useState<boolean>(false)

    useEffect(() => {
        console.log('Mounting main card')
    }, [])

    useEffect(() => {
        if (props.isCloudBroker) {
            return props.tabKeyState.setTabKey(TabKey.PLAYBACK_TAB_KEY)
        }
        props.tabKeyState.setTabKey(TabKey.RECORD_TAB_KEY)
    }, [props.isCloudBroker, props.isLicensed])

    // This effect is used to position the maincard when playback is selected, it should be sticky to the top
    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [props.tabKeyState.tabKey])

    useEffect(() => {
        const panelsContainer = document.getElementById('panel-container-id')
        const mainContainer = document.getElementById('maincard-container-id')
        const mainContainerCard = document.getElementById('maincard-container-card-id')
        if (
            props.tabKeyState.tabKey === TabKey.PLAYBACK_TAB_KEY &&
            panelsContainer !== null &&
            mainContainer !== null &&
            mainContainerCard !== null
        ) {
            if (isPlaybackBarSticky) {
                makePlaybackBarSticky(panelsContainer, mainContainer, mainContainerCard)
            } else {
                makePlaybackBarNotSticky(panelsContainer, mainContainer, mainContainerCard)
            }
        }
    }, [isPlaybackBarSticky])

    const onScroll = () => {
        if (!isIframe()) {
            if (window.scrollY > 130) {
                setIsPlaybackBarSticky(true)
            } else {
                setIsPlaybackBarSticky(false)
            }
        }
    }

    const makePlaybackBarSticky = (
        panelsContainer: HTMLElement,
        mainContainer: HTMLElement,
        mainContainerCard: HTMLElement
    ) => {
        if (ScreenSize.isSmallerThan(BootstrapBreakpoint.LG)) {
            const playbackCardHeight =
                document.getElementById('playback-container-id')?.getBoundingClientRect().height ?? 255
            mainContainer.classList.add('sticky-top')
            panelsContainer.style.marginTop = '30px'
            mainContainerCard.style.marginTop = `-${playbackCardHeight * 0.8}px`
        } else {
            mainContainer.classList.add('sticky-top')
            panelsContainer.style.marginTop = '65px'
            mainContainerCard.style.marginTop = '-50px'
        }
    }

    const makePlaybackBarNotSticky = (
        panelsContainer: HTMLElement,
        mainContainer: HTMLElement,
        mainContainerCard: HTMLElement
    ) => {
        mainContainer.classList.remove('sticky-top')
        panelsContainer.style.marginTop = '0'
        mainContainerCard.style.marginTop = '0'
    }

    const getContainer = () => {
        switch (props.tabKeyState.tabKey) {
            case TabKey.PLAYBACK_TAB_KEY:
                return (
                    <PlaybackContainer
                        isLicensed={props.isLicensed}
                        isClientConnectedToBroker={props.connectionState.clientIsConnectedToBroker}
                        listFilesFunction={props.listFilesFunction}
                        playbackState={props.playbackState}
                        refreshPanels={props.refreshPanels}
                        productAnalyticsTracker={props.productAnalyticsTracker}
                    />
                )

            case TabKey.RECORD_TAB_KEY:
            default:
                return (
                    <RecordContainer
                        isLicensed={props.isLicensed}
                        recordingState={props.recordingState}
                        currentStepperIndex={currentStepperIndex}
                        setCurrentStepperIndex={setCurrentStepperIndex}
                        selectedNamespaces={selectedNamespaces}
                        setSelectedNamespaces={setSelectedNamespaces}
                        selectedFilename={selectedFilename}
                        setSelectedFilename={setSelectedFilename}
                    />
                )
        }
    }

    const getTabs = () => {
        return (
            <Tabs
                id="controlled-tab-example"
                activeKey={props.tabKeyState.tabKey}
                style={{ marginBottom: -15 }}
                onSelect={(key) => props.tabKeyState.setTabKey(key || TabKey.RECORD_TAB_KEY)}
            >
                {!isIframe() && (
                    <Tab
                        tabClassName={`rounded-top-4 border-0 p-3 pb-4 mb-0 d-none d-md-block`}
                        eventKey={TabKey.RECORD_TAB_KEY}
                        title={
                            <div>
                                <p
                                className={`p-0 fs-6 m-0 remotive-font-sm ${
                                    props.tabKeyState.tabKey === TabKey.RECORD_TAB_KEY
                                        ? `${TAB_ACTIVE_COLOR}`
                                        : `${TAB_INACTIVE_COLOR}`
                                }`}
                            >
                                    <Record
                                        className={DEFAULT_TAB_STYLE}
                                        sx={{ fontSize: 18 }}
                                    />{' '}
                                    Record
                                </p>
                            </div>
                        }
                    />
                )}

                {!isIframe() && (
                    <Tab
                        tabClassName={`rounded-top-4 border-0 p-2 pb-3 mb-0 d-block d-md-none`}
                        eventKey={TabKey.RECORD_TAB_KEY}
                        title={
                            <p
                                className={`p-0 fs-6 m-0 mb-1 remotive-font-sm ${
                                    props.tabKeyState.tabKey === TabKey.RECORD_TAB_KEY
                                        ? `${TAB_ACTIVE_COLOR}`
                                        : `${TAB_INACTIVE_COLOR}`
                                }`}
                            >
                                <Record
                                    className={DEFAULT_TAB_STYLE}
                                    sx={{ fontSize: 18 }}
                                />{' '}
                                {props.tabKeyState.tabKey === TabKey.RECORD_TAB_KEY ? 'Record' : ''}
                            </p>
                        }
                    />
                )}
                <Tab
                    tabClassName={`rounded-top-4 border-0 p-3 mb-0 d-none d-md-block`}
                    eventKey={TabKey.PLAYBACK_TAB_KEY}
                    title={
                        <div>
                            <p
                                className={`p-0 fs-6 m-0 remotive-font-sm ${
                                    props.tabKeyState.tabKey === TabKey.PLAYBACK_TAB_KEY
                                        ? `${TAB_ACTIVE_COLOR}`
                                        : `${TAB_INACTIVE_COLOR}`
                                }`}
                            >
                                <Playback
                                    className={DEFAULT_TAB_STYLE}
                                    sx={{ fontSize: 18 }}
                                />{' '}
                                Play
                            </p>
                        </div>
                    }
                />
                <Tab
                    tabClassName={`rounded-top-4 border-0 p-2 pb-3 mb-0 d-block d-md-none`}
                    eventKey={TabKey.PLAYBACK_TAB_KEY}
                    title={
                        <p
                        className={`p-0 fs-6 m-0 mb-1 remotive-font-sm ${
                            props.tabKeyState.tabKey === TabKey.PLAYBACK_TAB_KEY
                                ? `${TAB_ACTIVE_COLOR}`
                                : `${TAB_INACTIVE_COLOR}`
                        }`}
                    >
                            <Playback
                                className={DEFAULT_TAB_STYLE}
                                sx={{ fontSize: 18 }}
                            />{' '}
                            {props.tabKeyState.tabKey === TabKey.PLAYBACK_TAB_KEY ? 'Play' : ''}
                        </p>
                    }
                />
            </Tabs>
        )
    }

    return (
        <>
            <div className={'row mb-3'} id="maincard-container-id">
                <div className="col-12 p-0">
                    <Card
                        className="border-0"
                        style={{
                            minHeight: '110px',
                            backgroundColor: 'transparent',
                        }}
                        id="maincard-container-card-id"
                    >
                        <Card.Header style={{backgroundColor: 'transparent'}} className={`main-card-header ps-2 pb-0 pt-0 border-0 mt-2`}>
                            {getTabs()}
                        </Card.Header>
                        <Card.Body className={`rounded-4 shadow-sm remotive-white-background pb-0 pt-2`}>
                            {getContainer()}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default MainCard
