import { Modal } from 'react-bootstrap'
import { isIframe } from '../../utils/CloudDetails'

interface InformationModalProps {
    show: boolean
    body: string
    title: string
    handleCloseFunction: () => void
}

function InformationModal(props: InformationModalProps) {
    return (
        <>
            <Modal
                style={{ zIndex: '100000' }}
                className="col-12"
                show={props.show}
                backdropClassName="z-index-max-background"
                onHide={props.handleCloseFunction}
            >
                <Modal.Header closeVariant="white" className="remotive-modal-header-bg" closeButton>
                    <Modal.Title className="lexend-regular text-light">{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row text-left">
                        <p dangerouslySetInnerHTML={{ __html: props.body.replace(/\n/g, '<br />') }} />
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={props.handleCloseFunction}>
                        OK
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default InformationModal
