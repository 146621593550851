import { Container, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap'
import Modify from '@mui/icons-material/CreateRounded'
import Info from '@mui/icons-material/InfoRounded'
import Record from '@mui/icons-material/FiberManualRecordRounded'

import { FullDiskError, Namespace, RecordingState, PlaybackStateFile } from '../api/BrokerApi/types'
import { useEffect, useState } from 'react'
import CreateRecordingModal from './modal/CreateRecordingModal'
import InformationModal from './modal/InformationModal'
import UploadButton from './buttons/UploadButton'
import { batchDeleteFiles, getAvailableFiles, getPlayableNamespaces, recordSession } from '../services/BrokerService'
import { getBrokerDetails } from '../utils/broker'
import { DeleteRounded, DownloadRounded } from '@mui/icons-material'

interface RecordContainerProps {
    recordingState: RecordingState | undefined
    currentStepperIndex: number
    setCurrentStepperIndex: (newIndex: number) => void
    selectedNamespaces: Array<Namespace>
    setSelectedNamespaces: (newArray: Array<Namespace>) => void
    selectedFilename: string
    setSelectedFilename: (newFilename: string) => void
    isLicensed: boolean
}

interface InformationModalText {
    title: string
    body: string
}

const TOOLTIP_ONE = (
    <Tooltip id="tooltip-recording-file">
        <p className="m-0 remotive-white-color-absolute">
            The recorded data will be stored in the file(s) below. The file(s) will overwrite any existing file(s) with
            the same name. The file(s) can be downloaded from the broker after the recording is done.
        </p>
    </Tooltip>
)

const STEPPER_ACTIVE_SIZE = {
    width: '30px',
    height: '30px',
    borderRadius: '20px',
    marginTop: '-6px',
}
const STEPPER_INACTIVE_SIZE = {
    width: '17px',
    height: '17px',
    borderRadius: '20px',
}
const STEPPER_CIRCLE_OFFSET = '8px'
const STEPPER_1_INDEX = 0
const STEPPER_2_INDEX = 1
const STEPPER_3_INDEX = 2
const STEPPER_BORDER_COLOR = '#7ca1c5'
const STEPPER_ACTIVE_COLOR = 'remotive-primary-80-background p-0'
const STEPPER_INACTIVE_COLOR = 'remotive-primary-10-background p-0'

function RecordContainer(props: RecordContainerProps) {
    const [availableFiles, setAvailableFiles] = useState<Array<PlaybackStateFile>>([])
    const [showCreateRecordingModal, setShowCreateRecordingModal] = useState(false)
    const [showInformationModal, setShowInformationModal] = useState(false)
    const [informationModalText, setInformationModalText] = useState<InformationModalText>({ title: '', body: '' })
    const [namespaces, setNamespaces] = useState<Array<Namespace>>([])
    const [downloadInProgress, setDownloadInProgress] = useState<string | undefined>()
    const [deleteInProgress, setDeleteInProgress] = useState<string>()

    useEffect(() => {
        console.log('Mounting recording container in main card')
        const fetchNamespaces = async () => {
            const allFrames = await getPlayableNamespaces()
            setNamespaces(allFrames)
            setAvailableFiles(await getAvailableFiles())
        }
        fetchNamespaces().catch((e: any) => {
            console.warn(`Failed to fetch playable namespaces for RecordContainer. Error: \n${JSON.stringify(e)}`)
            setNamespaces([])
            setAvailableFiles([])
        })
    }, [])

    useEffect(() => {
        console.log(
            `Recording state value was updated! recording=${props.recordingState?.isRecording()} stopped=${props.recordingState?.isStopped()}`
        )
        if (props.recordingState?.isRecording() === true) {
            props.setCurrentStepperIndex(STEPPER_3_INDEX)
        }
        if (props.recordingState?.isStopped() === true) {
            props.setCurrentStepperIndex(STEPPER_1_INDEX)
            getAvailableFiles().then((files) => setAvailableFiles(files))
        }
    }, [props.recordingState])

    const formatRecordingDuration = () => {
        if (props.recordingState) {
            const recordingDuration = Math.floor(props.recordingState.currentOffsetDurationMs() / 1000) // millis to seconds
            const minutes = Math.floor(recordingDuration / 60) // forcing integer division
            const seconds = recordingDuration % 60
            return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
        }
        return '00:00'
    }

    const renderFilenames = () => {
        if (props.recordingState && props.recordingState?.files().length > 0) {
            return props.recordingState?.files().map((file) => {
                return (
                    <div key={file.path()} className="d-flex rounded p-1 m-1 mt-0 remotive-primary-20-background">
                        <p className="m-0 p-0 px-1 text-break remotive-font-xs remotive-dark-color">
                            {file.path()}
                        </p>
                    </div>
                )
            })
        }
        return props.selectedNamespaces.map((namespace) => {
            return (
                <div
                    key={formatFilename(namespace.name, props.selectedFilename)}
                    className="d-flex rounded p-1 m-1 mt-0 remotive-primary-20-background"
                >
                    <p className="m-0 p-0 px-1 text-break remotive-font-xs remotive-dark-color">
                        {formatFilename(namespace.name, props.selectedFilename)}
                    </p>
                </div>
            )
        })
    }

    const removeFile = async (file: PlaybackStateFile) => {
        if (window.confirm(`Are you sure you want to delete this file: ${file.path()}`)) {
            try {
                setDeleteInProgress(file.path())
                await batchDeleteFiles([file])
                setDeleteInProgress(undefined)
                setAvailableFiles(await getAvailableFiles())
            } catch (err: any) {
                setDeleteInProgress(undefined)
                if (err.code) {
                    if (err.code === 5) {
                        alert('File does not exist')
                    } else {
                        alert('Unexpected error')
                    }
                }
                console.log(`${err.code} - ${err.message}`)
            }
        }
    }

    const renderAvailableFiles = () => {
        return availableFiles.map((file) => {
            return (
                <div key={`${file.namespace()}>${file.path()}`}>
                    <div className="d-flex rounded px-2 pt-1 pb-1 mb-1 mx-1 remotive-primary-20-background">
                        <label className="d-flex align-items-center m-0 d-flex-shrink-1 remotive-font-sm text-start remotive-dark-color">
                            <span className="d-inline-block">{`${file.path()}`}</span>
                            {!getBrokerDetails().isCloudBroker() && downloadLink(file.downloadUrl())}
                            {!getBrokerDetails().isCloudBroker() && deleteButton(file, deleteInProgress)}
                        </label>
                    </div>
                </div>
            )
        })
    }

    const downloadLink = (url: string | undefined) => {
        if (url) {
            return (
                <a
                    href={url}
                    className="border-0 bg-transparent remotive-primary-60-color d-inline-block"
                    style={{ padding: '2px', margin: '0 0 0 3px', position: 'relative', top: '0' }}
                >
                    <DownloadRounded sx={{ fontSize: 18 }} />
                </a>
            )
        }
    }

    const deleteButton = (file: PlaybackStateFile, deleteInProgress: string | undefined) => {
        if (deleteInProgress !== file.path()) {
            return (
                <button
                    onMouseUp={() => removeFile(file)}
                    className={'border-0 bg-transparent remotive-primary-60-color d-inline-block p-0 m-0'}
                >
                    <DeleteRounded sx={{ fontSize: 18 }} />
                </button>
            )
        } else if (deleteInProgress === file.path()) {
            return (
                <Spinner className={'spinner-border-sm'} animation="border" role="status">
                    <span className="visually-hidden">Waiting for broker</span>
                </Spinner>
            )
        }
    }

    const getEditRecordingFileButton = () => {
        if (props.currentStepperIndex !== STEPPER_3_INDEX) {
            return (
                <button
                    className="btn bg-transparent remotive-accessibility border-0 p-0 mx-1"
                    onClick={() => setShowCreateRecordingModal(true)}
                >
                    <Modify className="align-top remotive-primary-70-color" sx={{ fontSize: 22 }} />
                </button>
            )
        }
        return <></>
    }

    const recordingFileInfo = () => {
        return (
            <div className="mt-3 row d-flex justify-content-center" style={{ minWidth: '375px' }}>
                <div className="text-center">
                    <div className="rounded remotive-primary-10-background pt-1 pb-1 px-1 col-12 col-lg-10 offset-lg-1">
                        {/* Info and edit */}
                        <div className="d-flex justify-content-between px-1 pt-1 mb-0">
                            <div>
                                <OverlayTrigger placement="bottom" overlay={TOOLTIP_ONE}>
                                    <Info className="align-top remotive-primary-70-color" sx={{ fontSize: 22 }} />
                                </OverlayTrigger>
                            </div>
                            <div>{getEditRecordingFileButton()}</div>
                        </div>
                        {/* Filename */}
                        <div className="row px-1 mt-0 mb-1">
                            <div className="d-flex justify-content-center">
                                <div className="align-bottom">
                                    <p className="mb-1 remotive-font-sm">Filenames:</p>
                                    <div className="d-flex justify-content-center flex-wrap">{renderFilenames()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getStepper = () => {
        return (
            <div className="row">
                <div
                    className={
                        props.currentStepperIndex === STEPPER_1_INDEX ? STEPPER_ACTIVE_COLOR : STEPPER_INACTIVE_COLOR
                    }
                    style={{
                        ...(props.currentStepperIndex === STEPPER_1_INDEX
                            ? STEPPER_ACTIVE_SIZE
                            : STEPPER_INACTIVE_SIZE),
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        borderStyle: 'solid',
                    }}
                >
                    {props.currentStepperIndex === STEPPER_1_INDEX && <p className="text-light">1</p>}
                </div>
                <hr
                    className="align-middle"
                    style={{
                        width: '80px',
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        opacity: '1',
                        marginTop: STEPPER_CIRCLE_OFFSET,
                    }}
                />
                <div
                    className={
                        props.currentStepperIndex === STEPPER_2_INDEX ? STEPPER_ACTIVE_COLOR : STEPPER_INACTIVE_COLOR
                    }
                    style={{
                        ...(props.currentStepperIndex === STEPPER_2_INDEX
                            ? STEPPER_ACTIVE_SIZE
                            : STEPPER_INACTIVE_SIZE),
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        borderStyle: 'solid',
                    }}
                >
                    {props.currentStepperIndex === STEPPER_2_INDEX && <p className="text-light">2</p>}
                </div>
                <hr
                    className="align-middle"
                    style={{
                        width: '80px',
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        opacity: '1',
                        marginTop: STEPPER_CIRCLE_OFFSET,
                    }}
                />
                <div
                    className={
                        props.currentStepperIndex === STEPPER_3_INDEX ? STEPPER_ACTIVE_COLOR : STEPPER_INACTIVE_COLOR
                    }
                    style={{
                        ...(props.currentStepperIndex === STEPPER_3_INDEX
                            ? STEPPER_ACTIVE_SIZE
                            : STEPPER_INACTIVE_SIZE),
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        borderStyle: 'solid',
                    }}
                >
                    {props.currentStepperIndex === STEPPER_3_INDEX && <p className="text-light">3</p>}
                </div>
            </div>
        )
    }

    const isStepperContentVisible = (stepperIndex: number) => {
        if (stepperIndex === props.currentStepperIndex) {
            return 'd-block'
        }
        return 'd-none'
    }

    const formatFilename = (namespaceName: string, filename: string) => {
        return `${filename}_${namespaceName}`
    }

    const getRecordingStateIndicator = () => {
        if (props.recordingState && props.recordingState.isRecording()) {
            return (
                <div>
                    <div className="d-none d-md-block">
                        <p className="m-0 text-center">
                            Recording <Record className="text-danger blink" sx={{ fontSize: 25 }} />
                        </p>
                        <p className="m-0 text-center">{formatRecordingDuration()}</p>
                    </div>
                    <div className="d-block d-md-none d-inline">
                        <p className="m-0 mb-5 text-center">
                            Recording <Record className="text-danger blink" sx={{ fontSize: 25 }} />{' '}
                            {formatRecordingDuration()}
                        </p>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div className="d-none d-md-block">
                    <p className="m-0">
                        Not recording
                        <Record sx={{ fontSize: 25 }} color="disabled" />
                    </p>
                    <p className="m-0 text-center">-/-</p>
                </div>
                <div className="d-block d-md-none d-inline">
                    <p className="m-0 mb-5">
                        Not recording <Record sx={{ fontSize: 25 }} color="disabled" />
                    </p>
                </div>
            </div>
        )
    }

    const stopRecordingAndReset = async () => {
        props.recordingState?.stop()
        props.setCurrentStepperIndex(STEPPER_1_INDEX)
        props.setSelectedNamespaces([])
        props.setSelectedFilename('')
        setInformationModalText({
            title: 'Success',
            body: `Recording ${props.selectedFilename} was successfully saved and is now available for playback!`,
        })
        setShowInformationModal(true)
        setAvailableFiles(await getAvailableFiles())
    }

    const stopFailedRecordingAndReset = async (informationModalText: InformationModalText) => {
        props.recordingState?.stop()
        props.setCurrentStepperIndex(STEPPER_1_INDEX)
        props.setSelectedNamespaces([])
        props.setSelectedFilename('')
        setInformationModalText(informationModalText)
        setShowInformationModal(true)
        setAvailableFiles(await getAvailableFiles())
    }

    const startRecording = async () => {
        if (props.selectedNamespaces.length > 0 && props.selectedFilename) {
            const recordings = props.selectedNamespaces.map((namespace) => {
                return {
                    namespace: namespace.name,
                    filename: formatFilename(namespace.name, props.selectedFilename),
                }
            })

            try {
                await recordSession({ recordings })
            } catch (error: any) {
                if (error instanceof FullDiskError) {
                    await stopFailedRecordingAndReset({
                        title: 'Sorry, there seems to be an issue',
                        body:
                            `We can't start the recording '${props.selectedFilename}', because the broker is running out of disk space.\n\n` +
                            'Before you continue with new recordings, we suggest you download the existing recordings to your computer ' +
                            'and then delete them from the broker to free up the disk space.',
                    })
                } else {
                    await stopFailedRecordingAndReset({
                        title: 'Sorry, there seems to be an issue',
                        body:
                            `We can't start the recording '${props.selectedFilename}', however, it's not clear what is the issue.\n\n` +
                            'Please try again. If the problem continues contact support.',
                    })
                    throw error
                }
            }
        } else {
            setInformationModalText({
                title: 'Sorry, there seems to be an issue',
                body: 'You must select a namespace and set a filename before recording.',
            })
            setShowInformationModal(true)
        }
    }

    const recordAndProceed = () => {
        props.setCurrentStepperIndex(STEPPER_3_INDEX)
        startRecording()
    }

    const createRecordingFile = (namespaces: Array<Namespace>, filename: string) => {
        props.setSelectedNamespaces(namespaces)
        props.setSelectedFilename(filename)
        props.setCurrentStepperIndex(STEPPER_2_INDEX)
        setShowCreateRecordingModal(false)
    }

    const containerContent = () => {
        return (
            <>
                <Container fluid className="row m-0 mt-2 mb-2">
                    <div className="col-12 col-lg-8 text-center">
                        {/* Stepper */}
                        <div>
                            <div className="d-flex d-inline justify-content-between mb-0">
                                <div className="invisible">
                                    {/* This invisible divs content must match the last divs content */}
                                    <div className="d-none d-md-block">{getRecordingStateIndicator()}</div>
                                </div>
                                <div>
                                    {/* Stepper */}
                                    {getStepper()}
                                </div>
                                <div>
                                    {/* This is the last div */}
                                    {/* isRecording */}
                                    <div className="d-none d-md-block">{getRecordingStateIndicator()}</div>
                                </div>
                            </div>
                            <div className="remotive-font-sm d-md-none mt-0">{getRecordingStateIndicator()}</div>
                        </div>

                        {/* Step 1 */}
                        <div className={'row mt-1 ' + isStepperContentVisible(STEPPER_1_INDEX)}>
                            <div className="text-center">
                                <p className="ms-0 mb" style={{ marginTop: '-25px' }}>
                                    Create recording file
                                </p>
                                <button
                                    className="btn remotive-btn remotive-btn-primary"
                                    onClick={() => setShowCreateRecordingModal(true)}
                                >
                                    Create
                                </button>
                            </div>
                        </div>

                        {/* Step 2 */}
                        <div className={'row mt-1 ' + isStepperContentVisible(STEPPER_2_INDEX)}>
                            <div className="text-center">
                                <p className="ms-0 mb-0" style={{ marginTop: '-25px' }}>
                                    Record live data
                                </p>
                                <p className="m-0 mb-3  remotive-font-sm text-secondary" >
                                    Hit record to start recording signal data on all selected namespaces
                                </p>
                                <button
                                    className="btn remotive-btn remotive-btn-primary"
                                    onClick={() => recordAndProceed()}
                                >
                                    Record
                                </button>
                            </div>
                            <div className="d-flex justify-content-center">{recordingFileInfo()}</div>
                        </div>

                        {/* Step 3 */}
                        <div className={'row mt-1 ' + isStepperContentVisible(STEPPER_3_INDEX)}>
                            <div className="text-center">
                                <p className="ms-0 mb " style={{ marginTop: '-25px' }}>
                                    Stop & save recording
                                </p>
                                <button
                                    className="btn remotive-btn remotive-btn-primary"
                                    onClick={() => stopRecordingAndReset()}
                                >
                                    Stop & save
                                </button>
                            </div>
                            <div className="d-flex justify-content-center">{recordingFileInfo()}</div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="d-none d-lg-block border-start h-100">
                            <div className="ms-3">
                                <div className="d-flex d-inline justify-content-between mb-1">
                                    <p className="m-0 mt-2 text-start remotive-dark-color">Stored recordings</p>
                                    <div className="d-none d-xxl-block">
                                        <UploadButton
                                            size="lg"
                                            uploadDoneFunction={async () =>
                                                setAvailableFiles(await getAvailableFiles())
                                            }
                                        />
                                    </div>
                                    <div className="d-none d-lg-block d-xxl-none">
                                        <UploadButton
                                            uploadDoneFunction={async () =>
                                                setAvailableFiles(await getAvailableFiles())
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-wrap"
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: props.currentStepperIndex === STEPPER_1_INDEX ? '140px' : '205px',
                                    }}
                                >
                                    {renderAvailableFiles()}
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-lg-none p-0">
                            <div className="mt-3">
                                <hr className="remotive-primary-50-color col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3" />
                                <div className="mt-3">
                                    <UploadButton
                                        size="sm"
                                        uploadDoneFunction={async () => setAvailableFiles(await getAvailableFiles())}
                                    />
                                    <p className="mb-1 remotive-dark-color">Stored recordings</p>
                                </div>
                                <div
                                    className="d-flex justify-content-center flex-wrap"
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '90px',
                                    }}
                                >
                                    {renderAvailableFiles()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

                {/* Modals */}
                <CreateRecordingModal
                    show={showCreateRecordingModal}
                    handleCloseFunction={() => setShowCreateRecordingModal(false)}
                    handleCreateFunction={createRecordingFile}
                    namespaces={namespaces}
                />
                <InformationModal
                    show={showInformationModal}
                    handleCloseFunction={() => setShowInformationModal(false)}
                    title={informationModalText.title}
                    body={informationModalText.body}
                />
            </>
        )
    }

    return <>{containerContent()}</>
}

export default RecordContainer
