import { Card, Dropdown, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import VideoIcon from '@mui/icons-material/VideoCallRounded'
import MapIcon from '@mui/icons-material/PublicRounded'
import DropdownArrowIcon from '@mui/icons-material/ArrowDropDownRounded'
import AddIcon from '@mui/icons-material/AddRounded'
import HistogramIcon from '@mui/icons-material/LeaderboardRounded'
import { CloudContext, ConnectionState } from '../../services/LicenseService'
import { ProductAnalytics, ProductAnalyticsTracker } from '../../utils/ProductAnalytics'
import { PlaybackState } from '../../api/BrokerApi/types'
import { Features } from '../../services/FeatureToggleService'

interface AddPanelCardProps {
    connectionState: ConnectionState
    addFrameHistogramFunction: () => void
    addMapPanelFunction: () => void
    openModalFunction: () => void
    openAddVideoModalFunction: () => void
    playbackState: PlaybackState | undefined
    isCloudBroker: boolean
    cloudContext: CloudContext | undefined
    productAnalyticsTracker: ProductAnalyticsTracker
}

function AddPanelCard(props: AddPanelCardProps) {
    const [showDropdown, setShowDropdown] = useState(false)
    const [isFrameHistogramEnabled, setIsFrameHistogramEnabled] = useState(false)

    useEffect(() => {
        const toggleFeatures = async () => {
            const isEnabled = await Features.FRAME_HISTOGRAM.isEnabled()
            setIsFrameHistogramEnabled(isEnabled)
        }

        toggleFeatures()
    }, [])

    const addVisualizationDropdown = () => {
        return (
            <>
                <Dropdown show={showDropdown}>
                    <Dropdown.Menu className="py-2 text-left remotive-dropdown-light border-0 shadow">
                        {props.isCloudBroker && props.cloudContext && (
                            <Dropdown.Item
                                as={Button}
                                className="m-0 py-1 px-3 rounded-0 text-center remotive-font-md d-flex align-items-center justify-content-start"
                                // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                                onMouseDownCapture={() => {
                                    props.openAddVideoModalFunction()
                                    props.productAnalyticsTracker.track('Add video')
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <VideoIcon sx={{ fontSize: 20 }} />
                                    <p className="ms-2 m-0 remotive-font">Add video</p>
                                </div>
                            </Dropdown.Item>
                        )}
                        {(props.isCloudBroker || props.connectionState.clientHasInternet) && (
                            <Dropdown.Item
                                as={Button}
                                className="m-0 py-1 px-3 rounded-0 text-center remotive-font-md d-flex align-items-center justify-content-start"
                                // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                                onMouseDownCapture={() => {
                                    props.productAnalyticsTracker.track('Add map')
                                    props.addMapPanelFunction()
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <MapIcon sx={{ fontSize: 17 }} />
                                    <p className="ms-2 m-0 remotive-font">Add map</p>
                                </div>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item
                            as={Button}
                            disabled={!isFrameHistogramEnabled}
                            className="m-0 py-1 px-3 rounded-0 text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                props.productAnalyticsTracker.track('Add frame histogram')
                                props.addFrameHistogramFunction()
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <HistogramIcon sx={{ fontSize: 17 }} />
                                <p className="ms-2 m-0 remotive-font">Add frame histogram</p>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    const addVisualizationPanelButton = () => {
        return (
            <div>
                <div className="me-0">
                    <button
                        style={{
                            borderRadius: '40px 0px 0px 40px',
                        }}
                        className="btn p-1 m-0 mt-1 remotive-btn-primary"
                        onClick={async () => {
                            props.productAnalyticsTracker.track('Add signal visualizations')
                            props.openModalFunction()
                        }}
                    >
                        <div className="d-flex p-0 justify-content-center align-items-center ps-2 pe-1">
                            <AddIcon sx={{ fontSize: 33 }} />
                            <p className="mx-2 m-0 remotive-font">Add signal visualizations</p>
                        </div>
                    </button>
                    <button
                        style={{
                            borderRadius: '0px 40px 40px 0px',
                            marginLeft: '2px',
                        }}
                        className="btn border-0 py-1 mt-1 px-1 remotive-btn-primary-dark"
                        onBlur={() => {
                            setShowDropdown(false)
                        }}
                        onClick={() => {
                            setShowDropdown(!showDropdown)
                        }}
                    >
                        <DropdownArrowIcon sx={{ fontSize: 27 }} className="my-1" />
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <Card className="rounded bg-transparent border-0" style={{ height: '150px' }}>
                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    {addVisualizationPanelButton()}
                    {addVisualizationDropdown()}
                </div>
            </Card>
        </>
    )
}

export default AddPanelCard
