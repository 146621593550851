import React, { forwardRef } from 'react'
import { Popover } from 'react-bootstrap'
import SettingsPopoverItem, { SettingsPopoverItemProps } from './SettingsPopoverItem'

interface SettingsPopoverProps {
    items: Array<SettingsPopoverItemProps>
}

export default forwardRef(function SettingsPopoverProps(
    props: SettingsPopoverProps & React.ComponentProps<typeof Popover>,
    refFromParent: React.Ref<HTMLDivElement>
) {
    return (
        <Popover
            show={props.show}
            id="popover-basic"
            className="border-2 remotive-primary-20-border remotive-white-background shadow rounded-4"
            ref={refFromParent} // Pass the forwarded ref to the Popover
            {...props} // Forward props like placement, arrowProps, etc.
        >
            <Popover.Body className="pb-1">
                <div className="mb-3 text-center">
                    <div className="d-flex justify-content-center remotive-font-md flex-column">
                        {props.items.map((item, index) => (
                            <SettingsPopoverItem
                                key={index}
                                titleElement={item.titleElement}
                                actionElement={item.actionElement}
                            />
                        ))}
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )
})
