import { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { Container } from 'react-bootstrap'
import Play from '@mui/icons-material/PlayArrowRounded'
import Stop from '@mui/icons-material/StopRounded'
import Pause from '@mui/icons-material/PauseRounded'
import format from 'format-duration'
import Range from 'rc-slider'
import 'rc-slider/assets/index.css'

import SelectFileModal from './modal/SelectFileModal'
import { PlaybackState, PlaybackStateFile } from '../api/BrokerApi/types'
import UploadButton from './buttons/UploadButton'
import { getAvailableFiles, playFiles } from '../services/BrokerService'
import { ProductAnalyticsTracker } from '../utils/ProductAnalytics'
import { Mode } from 'remotivelabs-grpc-web-stubs'
import { isIframe } from '../utils/CloudDetails'
import { utcDateTimeWithMillis } from '../utils/DateFormatter'
import { useDarkMode } from '../hooks/useDarkMode'

interface PlaybackContainerProps {
    listFilesFunction: () => Promise<Array<PlaybackStateFile>>
    playbackState: PlaybackState | undefined
    isLicensed: boolean
    isClientConnectedToBroker: boolean
    refreshPanels: Function
    productAnalyticsTracker: ProductAnalyticsTracker
}

const COMMON_BUTTON_STYLING = 'btn main-card-button remotive-accessibility remotive-btn-no-bg border-0 p-0 mx-1'
const COLOR_BLUE = 'remotive-primary-50-color-absolute'

function PlaybackContainer(props: PlaybackContainerProps) {
    const [showSelectFileModal, setShowSelectFileModal] = useState(false)
    const [isSliderBeingDragged, setIsSliderBeingDragged] = useState<boolean>(false)
    const [currentSliderSeekValue, setCurrentSliderSeekValue] = useState<number | undefined>(undefined)
    const [forceDisablePlaybackControls, setForceDisablePlaybackControls] = useState<boolean>(false)

    const { isDarkMode } = useDarkMode()

    useEffect(() => {}, [props.playbackState])

    const renderPlaybackFiles: any = (textSizeClass: string = 'remotive-font-sm') => {
        const files = Array.from(props.playbackState?.files() || [])
        if (files.length > 0) {
            return files.map((file) => {
                return (
                    <div key={file.path()}>
                        <div className="d-flex rounded p-1 m-1 remotive-primary-20-background">
                            <p className={`m-0 mx-1 text-break ${textSizeClass} remotive-dark-color`}>
                                {`${file.namespace()} > ${file.path()}`}
                            </p>
                        </div>
                    </div>
                )
            })
        }
        return (
            <p className="m-0 text-secondary remotive-font-xs">Select a recording or upload one to start playback.</p>
        )
    }

    const playSelectedFiles = async (filesToPlay: Array<PlaybackStateFile>) => {
        return playFiles(filesToPlay, Mode.PAUSE)
    }

    const getDuration = () => {
        const duration = isSliderBeingDragged ? currentSliderSeekValue : props.playbackState?.currentOffsetDurationMs()
        return (
            <div className="mb-1 d-flex justify-content-center">
                <p className="m-0 remotive-font-md">
                    {format(duration || 0)} / {format(props.playbackState?.totalDurationMs() || 1)}
                </p>
                {isSliderBeingDragged && props.playbackState !== undefined && (
                    <div style={{ marginTop: 20, zIndex: 2 }} className="position-absolute p-2 rounded-3 bg-dark">
                        <p className="m-0 remotive-font-sm text-light">
                            {utcDateTime(props.playbackState, currentSliderSeekValue ?? 0)}
                        </p>
                    </div>
                )}
            </div>
        )
    }

    const utcDateTime = (playbackState: PlaybackState, offsetFromPlaybackState: number) => {
        const date = new Date(playbackState.startTimestampMs() - playbackState.offsetWallClockVsSampleMs() + offsetFromPlaybackState)
        return utcDateTimeWithMillis(date)
    }

    const getProgressBarValue = () => {
        if (isSliderBeingDragged) {
            return currentSliderSeekValue
        }
        return props.playbackState?.currentOffsetDurationMs() ?? 0
    }

    const playbackErrorMessage = (playbackType: 'play' | 'pause' | 'stop') => {
        return (
            <>
                <p className="text-start remotive-font m-0">
                    <b>There was an error trying to {playbackType} the current recording.</b> <br />{' '}
                    <span className="remotive-font-sm">
                        If this keeps happening, you might have to reload the application.
                    </span>
                </p>
            </>
        )
    }

    const selectRecordingAndUploadButtons = () => {
        return (
            <>
                <div className="d-none d-xxl-block">
                    <button
                        className="btn remotive-btn-md remotive-btn-primary me-2"
                        onClick={() => setShowSelectFileModal(true)}
                    >
                        Select recording
                    </button>
                    {!isIframe() && <UploadButton size="lg" />}
                </div>
                <div className="d-none d-lg-block d-xxl-none">
                    <button
                        className="btn remotive-btn-sm remotive-btn-primary me-2"
                        onClick={() => setShowSelectFileModal(true)}
                    >
                        Select <span className="d-none d-xl-inline-block">recording</span>
                    </button>
                    {!isIframe() && <UploadButton />}
                </div>
            </>
        )
    }

    const disableControlsAndchangePlaybackState = async (
        changePlaybackStateFunction: () => Promise<void>,
        delayInMs: number
    ) => {
        setForceDisablePlaybackControls(true)
        await changePlaybackStateFunction()
        await new Promise((r) => setTimeout(r, delayInMs))
        setForceDisablePlaybackControls(false)
        setIsSliderBeingDragged(false)
    }

    const containerContent = () => {
        return (
            <>
                <Container fluid className="row m-0 pt-0 mb-0 d-flex" id={'playback-container-id'}>
                    {/* Currently playing section that is used on smaller screens */}
                    <div className="col-12 d-block d-lg-none p-0 mb-0 mt-0">
                        <div className="m-0">
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn remotive-btn-sm remotive-btn-primary"
                                    onClick={() => setShowSelectFileModal(true)}
                                >
                                    Select
                                </button>
                                <UploadButton size="sm" />
                            </div>
                            <p className="text-center fs-6 remotive-primary-70-color mb-0">Current recording:</p>
                            <div className="d-flex flex-wrap justify-content-center">
                                {renderPlaybackFiles('remotive-font-xs')}
                            </div>
                        </div>
                    </div>
                    {/* Play/pause/stop buttons and duration slider */}
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            {/* Disabled if something is already stopped, enabled when something is paused or playing */}

                            <button
                                disabled={
                                    forceDisablePlaybackControls ||
                                    !props.playbackState ||
                                    props.playbackState?.isStopped()
                                }
                                onClick={async () => {
                                    try {
                                        disableControlsAndchangePlaybackState(
                                            async () => props.playbackState!.seek(0),
                                            1_000
                                        )
                                    } catch (error) {
                                        toast.error(playbackErrorMessage('stop'), { autoClose: 10_000 })
                                    }
                                }}
                                className={`${COMMON_BUTTON_STYLING} ${COLOR_BLUE}`}
                            >
                                <Stop sx={{ fontSize: 30 }} />
                            </button>
                            {/* Disabled if something is already playing, enabled when something is paused */}
                            <button
                                disabled={
                                    forceDisablePlaybackControls ||
                                    !props.playbackState ||
                                    props.playbackState?.isPlaying()
                                }
                                onClick={async () => {
                                    props.productAnalyticsTracker.track('Play')
                                    try {
                                        if (
                                            props.playbackState !== undefined &&
                                            props.playbackState.files().length > 0
                                        ) {
                                            disableControlsAndchangePlaybackState(
                                                async () => props.playbackState!.play(),
                                                500
                                            )
                                        } else {
                                            toast.error(playbackErrorMessage('play'), { autoClose: 10_000 })
                                        }
                                    } catch (error) {
                                        toast.error(playbackErrorMessage('play'), { autoClose: 10_000 })
                                    }
                                }}
                                className={`${COMMON_BUTTON_STYLING} ${COLOR_BLUE}`}
                            >
                                <Play sx={{ fontSize: 60 }} />
                            </button>
                            {/* Disabled if something is already paused or stopped, enabled when something is playing */}
                            <button
                                disabled={
                                    forceDisablePlaybackControls ||
                                    !props.playbackState ||
                                    props.playbackState?.isPaused() ||
                                    props.playbackState?.isStopped()
                                }
                                onClick={async () => {
                                    try {
                                        disableControlsAndchangePlaybackState(
                                            async () => props.playbackState!.pause(),
                                            500
                                        )
                                    } catch (error) {
                                        toast.error(playbackErrorMessage('pause'), { autoClose: 10_000 })
                                    }
                                }}
                                className={`${COMMON_BUTTON_STYLING} ${COLOR_BLUE}`}
                            >
                                <Pause sx={{ fontSize: 30 }} />
                            </button>

                            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 mb-1">
                                <Range
                                    disabled={forceDisablePlaybackControls || !props.playbackState}
                                    onBeforeChange={(value) => {
                                        setIsSliderBeingDragged(true)
                                    }}
                                    onChange={(current) => {
                                        setCurrentSliderSeekValue(current as number)
                                    }}
                                    onAfterChange={(newOffset) => {
                                        disableControlsAndchangePlaybackState(
                                            async () => props.playbackState!.seek(newOffset as number),
                                            1_000
                                        )
                                    }}
                                    handleStyle={{
                                        backgroundColor: isDarkMode ? '#1e3a5f' : '#B6F0C3', // A darker blue for dark mode
                                        borderColor: isDarkMode ? '#89CFF0' : '#0b3f70',     // Light blue for dark mode
                                        opacity: '1',
                                    }}
                                    trackStyle={{
                                        backgroundColor: isDarkMode ? '#89CFF0' : '#0b3f70', // Light blue for dark mode
                                    }}
                                    railStyle={{
                                        backgroundColor: isDarkMode ? '#374151' : '#d2e1ee', // Dark gray for dark mode
                                    }}
                                    min={0}
                                    max={props.playbackState?.totalDurationMs()}
                                    value={getProgressBarValue()}
                                />
                            </div>
                            {props.playbackState !== undefined && (
                                <div className="d-flex justify-content-center">{getDuration()}</div>
                            )}
                        </div>
                        {/* Currently playing section that is used on larger screens */}
                        <div className="col-lg-5 d-none d-lg-block d-flex mb-2">
                            <div className="p-0 pt-2 border-start h-100">
                                <div className="ms-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-text-bottom">
                                            <p className="text-start fs-6 remotive-dark-color mt-2 mb-2">
                                                Current recording:
                                            </p>
                                        </div>
                                        <div>{selectRecordingAndUploadButtons()}</div>
                                    </div>
                                    <div
                                        className="d-flex m-0 flex-wrap flex-shrink-1 justify-content-start"
                                        style={{
                                            overflowY: 'auto',
                                            maxHeight: '80px',
                                        }}
                                    >
                                        {renderPlaybackFiles()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                {/* Modals */}
                <SelectFileModal
                    show={showSelectFileModal}
                    handleCloseFunction={() => setShowSelectFileModal(false)}
                    availablePlaybackFiles={async () => await getAvailableFiles()}
                    playbackState={props.playbackState}
                    playSelectedFilesFunction={playSelectedFiles}
                    refreshPanels={props.refreshPanels}
                />
            </>
        )
    }

    return <>{containerContent()}</>
}

export default PlaybackContainer
