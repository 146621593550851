import { ToastContainer } from "react-toastify";
import { useDarkMode } from "../hooks/useDarkMode";

export function RemotiveToastContainer() {

    const { isDarkMode } = useDarkMode()
    
    return (
        <ToastContainer
            stacked
            pauseOnFocusLoss={false}
            position="bottom-right"
            autoClose={9_000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            toastClassName={
                'rounded-3 remotive-dark-color remotive-primary-40-border remotive-primary-5-background shadow'
            }
            bodyClassName={'lexend-regular remotive-font-md'}
            rtl={false}
            draggable
            pauseOnHover
            theme={isDarkMode ? 'dark' : 'light'}
        />
    )
}
