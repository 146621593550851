import { AnnouncementRounded, ErrorRounded, WarningRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { CloudContext } from '../../services/LicenseService'
import CloudApi from '../../api/CloudApi'
import BrokerDetailsInfo from '../BrokerDetailsInfo'
import { getBrokerDetails } from '../../utils/broker'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../utils/toast'
import AppStorage from '../../utils/AppStorage'
import { minHeight } from '@mui/system'
import { isIframe } from '../../utils/CloudDetails'

interface BrokerIssueInfoModalProps {
    issue: 'DOES_NOT_EXIST' | 'IDLE' | 'UNKNOWN'
    cloudContext: CloudContext | undefined
    show: boolean
    handleCloseFunction: () => void
}

function BrokerIssueInfoModal(props: BrokerIssueInfoModalProps) {
    const [isApplyingPreviousRecordingSession, setIsApplyingPreviousRecording] = useState<boolean>(false)
    const [failedToApplyPreviousRecordingSession, setFailedToApplyPreviousRecordingSession] = useState<boolean>(false)

    useEffect(() => {
        console.log('Mounting broker issue info modal!')
    }, [])

    const reapplyPreviousRecordingSession = async () => {
        try {
            setIsApplyingPreviousRecording(true)
            const storedCloudContext = JSON.parse(
                AppStorage.getItem(AppStorage.CLOUD_CONTEXT_STORAGE_KEY) ?? ''
            ) as CloudContext
            if (storedCloudContext !== undefined) {
                const projectName = storedCloudContext.project
                const brokerName = getBrokerDetails().brokerUrl.split(`-${projectName}`)[0].replace('https://', '')
                const recordingSessionId = storedCloudContext.recordingSession
                const brokerConfigName = storedCloudContext.brokerConfigName ?? undefined
                await CloudApi.applyRecordingSessionToBroker(
                    projectName,
                    recordingSessionId,
                    brokerName,
                    brokerConfigName
                )
                window.location.reload()
            }
        } catch (e: any) {
            console.error(`Failed to reapply previous recording session, ${e}`)
            toast.error(
                formattedToastMessage(
                    'Error',
                    'Failed to resume the recording session. Please close this session and select another one or try again later.'
                ),
                { autoClose: 25_000 }
            )
            setIsApplyingPreviousRecording(false)
            setFailedToApplyPreviousRecordingSession(true)
        }
    }

    const unknownIssueBody = () => {
        return (
            <>
                <div className="mx-2 mb-4 rounded p-3">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="d-flex justify-content-start  me-3 h-100">
                            <WarningRounded className="text-warning" sx={{ fontSize: 40 }} />
                        </div>
                        <div className="d-flex flex-column justify-content-start">
                            <p className="m-0 fs-5">
                                <b>Unknown issue</b>
                            </p>
                            <p className="m-0 remotive-font-md">
                                Go back to RemotiveCloud console or try refreshing the application.
                            </p>
                            <p className="mb-1 remotive-font-xs text-secondary">
                                There was an unknown issue with the RemotiveBroker, try refreshing the application or go
                                back to RemotiveCloud console.
                            </p>
                            <p className="mb-1 remotive-font-md"></p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <button
                        className="btn remotive-btn remotive-btn-primary me-2"
                        onClick={() => window.location.reload()}
                    >
                        Refresh
                    </button>
                    {previousRecordingOrReturnButton()}
                </div>
            </>
        )
    }

    const brokerIdle = () => {
        if (isApplyingPreviousRecordingSession) {
            return (
                <>
                    <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                        <Spinner className="me-2 mb-1" />
                        <p className="m-0 remotive-font">Resuming recording session...</p>
                        <p className="m-0 text-secondary remotive-font-sm">
                            The window will refresh once the recording session has been resumed.
                        </p>
                    </div>
                </>
            )
        }
        if (isIframe()) {
            return (
                <div className="mx-2 rounded p-3">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="d-flex justify-content-start  me-3 h-100">
                            <AnnouncementRounded className="remotive-success-60-color" sx={{ fontSize: 40 }} />
                        </div>
                        <div className="d-flex flex-column justify-content-start">
                            <p className="m-0 fs-5">
                                <b>Recording session stopped due to inactivity</b>
                            </p>
                            {failedToApplyPreviousRecordingSession ? (
                                <>
                                    <p className="m-0 remotive-font-md">
                                        We could not resume the recording session, click the button below to close this
                                        session.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p className="m-0 remotive-font-md">
                                        The recording session was stopped due to inactivity. You can resume the
                                        recording session by clicking the button below.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-5 mb-3">
                        <div>{previousRecordingOrReturnButton()}</div>
                    </div>
                </div>
            )
        }
        return (
            <>
                <div className="mx-2 rounded p-3">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="d-flex justify-content-start  me-3 h-100">
                            <AnnouncementRounded className="remotive-success-60-color" sx={{ fontSize: 40 }} />
                        </div>
                        <div className="d-flex flex-column justify-content-start">
                            <p className="m-0 fs-5">
                                <b>There is no recording available</b>
                            </p>
                            <p className="m-0 remotive-font-md">
                                You can use the previous recording session or simply close this and use the broker
                                without a recording.
                            </p>
                            <p className="mb-1 remotive-font-sm text-secondary">
                                RemotiveBrokers go idle when there hasn't been any activity for an extended period of
                                time. When the broker goes idle, all recording data is wiped and has to be reapplied.
                            </p>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-5 mb-3">
                        <div className="me-2">{closeModalButton()}</div>
                        <div>{previousRecordingOrReturnButton()}</div>
                    </div>
                </div>
            </>
        )
    }

    const doesNotExistBody = () => {
        return (
            <>
                <div className="mx-2 mb-4 rounded p-3 d-flex flex-column">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="d-flex justify-content-start  me-3 h-100">
                            <ErrorRounded className="text-danger" sx={{ fontSize: 40 }} />
                        </div>
                        <div className="d-flex flex-column justify-content-start">
                            <p className="m-0 fs-5">
                                <b>This broker does not seem to exist</b>
                            </p>
                            <p className="m-0 remotive-font-md">
                                Go back to RemotiveCloud console and select another broker or recording.
                            </p>
                            <p className="mb-1 remotive-font-xs text-secondary">
                                The RemotiveBroker might have been deleted or we're having issues communicating with it.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-3 mb-4">{returnToCloudConsoleButton()}</div>
            </>
        )
    }

    const returnToCloudConsoleButton = () => {
        if (isIframe()) {
            return <></>
        }
        return (
            <>
                <button
                    onClick={() => window.location.replace(`${CloudApi.getConsoleAppReturnUrl()}`)}
                    className="btn remotive-btn remotive-btn-success"
                >
                    Return to cloud console
                </button>
            </>
        )
    }

    const previousRecordingButton = () => {
        return (
            <>
                <button
                    onClick={() => reapplyPreviousRecordingSession()}
                    className="btn remotive-btn remotive-btn-success"
                >
                    Resume
                </button>
            </>
        )
    }

    const closeModalButton = () => {
        return (
            <>
                <button onClick={() => props.handleCloseFunction()} className="btn remotive-btn remotive-btn-primary">
                    Close
                </button>
            </>
        )
    }

    const previousRecordingOrReturnButton = () => {
        if (failedToApplyPreviousRecordingSession) {
            return returnToCloudConsoleButton()
        }
        return previousRecordingButton()
    }

    const getTitle = () => {
        switch (props.issue) {
            case 'IDLE':
                return 'Session stopped'

            case 'DOES_NOT_EXIST':
                return 'Broker issue'

            default:
            case 'UNKNOWN':
                return 'Unknown broker issue'
        }
    }

    const getBody = () => {
        switch (props.issue) {
            case 'DOES_NOT_EXIST':
                return doesNotExistBody()

            case 'IDLE':
                return brokerIdle()

            default:
            case 'UNKNOWN':
                return unknownIssueBody()
        }
    }

    return (
        <>
            <Modal className="col-12 border-0" show={props.show}>
                <Modal.Header className="remotive-modal-header-bg">
                    <Modal.Title className="lexend-regular text-light">{getTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ minHeight: 300 }}
                >
                    {getBody()}
                </Modal.Body>
                {props.show && (
                    <div
                        style={{ zIndex: 9999 }}
                        className="fixed-bottom col-12 col-md-10 col-lg-8 col-xl-6 px-2 fade-in"
                    >
                        <BrokerDetailsInfo asModal={false} />
                    </div>
                )}
            </Modal>
        </>
    )
}

export default BrokerIssueInfoModal
