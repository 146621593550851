const LAST_PROJECT_LOCAL_STORAGE_KEY = 'LastProject'
const IS_DEMO_QUERY_PARAM_KEY = 'is_demo'
const IS_DEMO_HOST_URL = 'broker-app.demo.'

export function setLastProject(lastProject: string | null) {
    if (lastProject !== null) {
        localStorage.setItem(LAST_PROJECT_LOCAL_STORAGE_KEY, lastProject)
    }
}

export function getLastProject() {
    return localStorage.getItem(LAST_PROJECT_LOCAL_STORAGE_KEY)
}

export function isIframe() {
    const urlParams = new URLSearchParams(window.location.search)
    const isIframe = urlParams.get('is_iframe')
    return isIframe === 'true'
}

export function isDemo() {
    if (window.location.host.startsWith(IS_DEMO_HOST_URL)) {
        return true
    }

    let params = new URLSearchParams(window.location.search)
    const isDemoMode = params.get(IS_DEMO_QUERY_PARAM_KEY)

    return isDemoMode === 'true'
}
