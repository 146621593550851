import { ContentCopyRounded } from '@mui/icons-material'
import ExternalLinkIcon from '@mui/icons-material/LaunchRounded'

export type CliHint = {
    title: string
    subtitle?: string
    command: string
}

interface CliHintContainerProperties {
    hints: Array<CliHint>
}

export default function CliHintContainer(props: CliHintContainerProperties) {
    const hint = (hint: CliHint) => {
        return (
            <div className={'text-start ms-2 mt-2 mb-0 pb-1'} key={hint.command}>
                <p className={'remotive-font-md p-0 m-0 remotive-dark-color'}>{hint.title}</p>
                {hint.subtitle && <p className={'remotive-font-sm remotive-secondary-color p-0 m-0'}>{hint.subtitle}</p>}
                <div style={{ borderLeft: '3px solid #4375a5' }} className={'m-1 ms-0 p-1 code-box text-wrap'}>
                    {hint.command}{' '}
                    <button
                        className="remotive-btn-sm remotive-btn-no-bg border-0"
                        onClick={() => {
                            navigator.clipboard.writeText(hint.command)
                        }}
                    >
                        <ContentCopyRounded sx={{ fontSize: '1.1em' }} className="remotive-btn-copy " />
                    </button>
                </div>
            </div>
        )
    }

    const getBody = () => {
        return<>
        {props.hints.map(hint)}
        <a
            className={'remotive-dark-color text-decoration-none'}
            href={'https://docs.remotivelabs.com/docs/remotive-cli'}
            target={'_blank'}
        >
            <div className="d-flex align-items-center mt-4 mb-1">
                <ExternalLinkIcon sx={{ fontSize: 15 }} className="me-2" />
                <p className="mb-0 remotive-font-sm text-start">CLI docs</p>
            </div>
        </a>
    </>
    }

    return <div className="mt-2">{getBody()}</div>
}
