import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react'

// Define the type for the context value
export interface DarkModeContextType {
    isDarkMode: boolean
    setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}

// Define the type for the provider's props
interface DarkModeProviderProps {
    children: ReactNode
}

// Create the context with an initial undefined value
const DarkModeContext = createContext<DarkModeContextType | undefined>(undefined) // Note: This is a context object, not a namespace.

const DARK_MODE_STORAGE_KEY = 'IS_DARK_MODE'
const DARK_MODE_CLASS_NAME = 'dark-mode'

// DarkModeProvider component
export const DarkModeProvider: React.FC<DarkModeProviderProps> = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(localStorage.getItem(DARK_MODE_STORAGE_KEY) === 'true')

    useEffect(() => {
        const root = document.documentElement
        if (isDarkMode) {
            root.classList.add(DARK_MODE_CLASS_NAME)
        } else {
            root.classList.remove(DARK_MODE_CLASS_NAME)
        }
        localStorage.setItem(DARK_MODE_STORAGE_KEY, `${isDarkMode}`)
    }, [isDarkMode])

    // Memoize the context value to prevent unnecessary re-renders
    const value = useMemo(() => ({ isDarkMode, setIsDarkMode }), [isDarkMode])

    return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>
}

// Hook to use the DarkModeContext
export const useDarkMode = (): DarkModeContextType => {
    const context = useContext(DarkModeContext)
    if (!context) {
        throw new Error('useDarkMode must be used within a DarkModeProvider')
    }
    return context
}
