import { Alert, Modal } from 'react-bootstrap'

interface StoredSettingsBannerProps {
    show: boolean
    handleCloseFunction: () => void
    removePanelsFromStorageFunction: () => void
    setUseStoredSession: Function
}

function StoredSettingsBanner(props: StoredSettingsBannerProps) {
    const createPanelsFromStorageOption = () => {
        props.handleCloseFunction()
        props.setUseStoredSession(true)
    }

    const removePanelsFromStorageOption = () => {
        props.removePanelsFromStorageFunction()
        props.handleCloseFunction()
    }

    return (
        <>
            <Alert
                style={{ maxWidth: 650 }}
                dismissible={true}
                className="remotive-white-background remotive-dark-color border-0 shadow"
                show={props.show}
                onClose={removePanelsFromStorageOption}
            >
                <div>
                    <div className="row text-start">
                        <p className='mb-1'>
                            It looks like you have used this configuration & recording before,{' '}
                            <strong>do you want to begin this session with the same panels?</strong>
                        </p>
                        <p className="remotive-font-sm remotive-neutral-70-color">
                            You can still customize your panels like usual, but the stored panels will be pre-selected
                            for you.
                        </p>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn remotive-btn-md remotive-btn-primary"
                            onClick={removePanelsFromStorageOption}
                        >
                            No, start over
                        </button>
                        <button
                            className="btn remotive-btn-md remotive-btn-success ms-1"
                            onClick={createPanelsFromStorageOption}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Alert>
        </>
    )
}

export default StoredSettingsBanner
