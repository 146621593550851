import { SensorsOffRounded } from '@mui/icons-material'
import { CloudContext, ConnectionState } from '../services/LicenseService'
import { Spinner } from 'react-bootstrap'
import { LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import BrokerDetailsInfo from './BrokerDetailsInfo'
import DisconnectedBrokerText from './DisconnectedBrokerText'
import { isIframe } from '../utils/CloudDetails'

interface BrokerInformationFooterProps {
    licenseInfo: LicenseInfo | undefined
    isLicensed: boolean
    isCloudBroker: boolean
    isAppInitialized: boolean
    connectionState: ConnectionState
    cloudContext: CloudContext | undefined
}

export default function BrokerInformationFooter(props: BrokerInformationFooterProps) {
    const loadingSpinner = () => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center">
                <Spinner style={{ height: 12, width: 12 }} size="sm" className="me-2 mb-1" />
                <p className="m-0 remotive-font-xs">Trying to reconnect...</p>
            </div>
        )
    }

    const getNoConnectionBar = () => {
        return (
            <div className="d-flex justify-content-center" style={{ opacity: '0.95' }}>
                <div className="rounded-4 shadow remotive-warning-10-background m-4 mb-0 p-1">
                    <div className="">
                        <div className="p-5 pb-4">
                            <SensorsOffRounded sx={{ fontSize: 70 }} />
                            <DisconnectedBrokerText />
                            {loadingSpinner()}
                        </div>
                        <div>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <BrokerDetailsInfo asModal={true} />
                            </div>
                            <div className="d-flex justify-content-center">
                                <p className="remotive-font-xs col-8 mb-2">
                                    Taking too long...? Double check broker details and make sure you are connecting to
                                    the correct URL.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const isNotCloudBrokerWithoutCloudContext = !(props.isCloudBroker && props.cloudContext === undefined)
    const isNotConnectedToBroker = !props.connectionState.clientIsConnectedToBroker
    const showDisconnectedMessage =
        props.isLicensed && props.isAppInitialized && isNotCloudBrokerWithoutCloudContext && isNotConnectedToBroker

    return (
        <>
            {showDisconnectedMessage && (
                <div
                    style={{ height: '100vh', width: '100vw' }}
                    className="fixed-top remotive-primary-10-background opacity-85"
                />
            )}
            <div className="fixed-bottom mb-5">{showDisconnectedMessage && getNoConnectionBar()}</div>
            {/* Sticky broker info, we use Bootstrap columns so that it does not entirely cover the clickable area at the bottom of the page */}

            {!showDisconnectedMessage && (
                <div className="fixed-bottom col-12 col-md-10 col-lg-5 col-xl-4 px-2">
                    <BrokerDetailsInfo asModal={true} />
                </div>
            )}
        </>
    )
}
