import { Form, Modal, Spinner } from 'react-bootstrap'
import { ConnectionState } from '../../../services/LicenseService'
import { useState } from 'react'
import Stepper from '../../Stepper'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../utils/toast'

interface DisconnectedBrokerFlowProps {
    connectionState: ConnectionState
    handleCloseFunction: () => void
    goToApplyLicenseFunction: (specifiedEmail: string) => void
    sendLicenseEmailAndUpdateComponentState: (
        setCurrentStepFunction: () => void,
        setIsSendingEmailFunction: () => void
    ) => void
    specifiedEmail: string
    setSpecifiedEmail: (e: any) => void
}

export default function DisconnectedBrokerFlow(props: DisconnectedBrokerFlowProps) {
    const [currentStep, setCurrentStep] = useState<number>(1)
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false)

    const stepOneContent = () => {
        const emailValid = props.specifiedEmail.includes('@') && props.specifiedEmail.includes('.')
        return (
            <>
                <div className="d-flex align-items-center flex-column">
                    <Stepper currentStep={1} maxSteps={2} />
                    <div className="mt-5 mb-5 px-5">
                        <div className="mb-3 text-start">
                            <p className="m-0 remotive-font">Input your email address to receive a license</p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                If you already have a license for this hardware, we will re-send your previous license.
                            </p>
                        </div>
                        <Form.Group>
                            <Form.Label className={'remotive-font-xs m-0'}>Email</Form.Label>
                            <Form.Control
                                size={'sm'}
                                type="email"
                                id="inputEmail"
                                disabled={isSendingEmail}
                                className="dark-and-light-placeholder"
                                placeholder="E.g johndoe@example.com"
                                value={props.specifiedEmail}
                                isInvalid={props.specifiedEmail !== '' && !emailValid}
                                onChange={(event: any) => props.setSpecifiedEmail(event.target.value)}
                            />
                            <Form.Control.Feedback type="invalid" className="remotive-font-sm">
                                You need to enter a valid email address
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="mt-5 mb-3 d-flex justify-content-evenly flex-row">
                    <button
                        className="btn remotive-btn remotive-btn-primary"
                        onClick={() => props.handleCloseFunction()}
                    >
                        Close
                    </button>
                    <button
                        className="btn remotive-btn remotive-btn-success"
                        disabled={isSendingEmail || !emailValid}
                        onClick={() => {
                            setIsSendingEmail(true)
                            props.sendLicenseEmailAndUpdateComponentState(
                                () => setCurrentStep(2),
                                () => setIsSendingEmail(false)
                            )
                        }}
                    >
                        {isSendingEmail ? <Spinner size="sm" /> : 'Next'}
                    </button>
                </div>
            </>
        )
    }

    const stepTwoContent = () => {
        return (
            <>
                <div className="d-flex align-items-center flex-column">
                    <Stepper currentStep={2} maxSteps={2} />
                    <div className="mt-5 mb-5 px-5">
                        <div className="mb-5 text-start">
                            <p className="m-0 remotive-font lexend-bold">
                                Check your inbox for an email from support@remotivelabs.com
                            </p>
                            <p className="m-0 remotive-font-md text-secondary">
                                The email contains a license phrase and a license file, you can use either or.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-5 mb-3 d-flex justify-content-evenly flex-row">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={() => setCurrentStep(1)}>
                        Back
                    </button>
                    <button
                        className="btn remotive-btn remotive-btn-success"
                        disabled={!props.connectionState.clientHasInternet}
                        onClick={() => {
                            props.goToApplyLicenseFunction(props.specifiedEmail)
                            props.handleCloseFunction()
                        }}
                    >
                        I received it
                    </button>
                </div>
            </>
        )
    }

    const getCurrentStepBody = () => {
        switch (currentStep) {
            default:
            case 1:
                return stepOneContent()

            case 2:
                return stepTwoContent()
        }
    }

    const getCurrentStep = () => {
        return (
            <>
                <Modal.Body>{getCurrentStepBody()}</Modal.Body>
            </>
        )
    }

    return <>{getCurrentStep()}</>
}
