const STEPPER_ACTIVE_SIZE = {
    width: 30,
    height: 30,
    borderRadius: 20,
    marginTop: -6,
}
const STEPPER_INACTIVE_SIZE = {
    width: 17,
    height: 17,
    borderRadius: 20,
}

const STEPPER_CIRCLE_OFFSET = '8px'
const STEPPER_BORDER_COLOR = '#7ca1c5'
const STEPPER_ACTIVE_COLOR = 'remotive-primary-80-background p-0'
const STEPPER_INACTIVE_COLOR = 'remotive-primary-10-background p-0'

interface StepperProps {
    currentStep: number
    maxSteps: number
}

export default function Stepper(props: StepperProps) {
    const line = (key: number) => {
        return (
            <hr
                key={`stepper-line-${key}`}
                style={{
                    width: '80px',
                    borderWidth: '2px',
                    borderColor: STEPPER_BORDER_COLOR,
                    opacity: '1',
                    marginTop: STEPPER_CIRCLE_OFFSET,
                }}
            />
        )
    }

    const circleWithNumber = (number: number, currentNumber: number, key: number) => {
        return (
            <div
                key={`stepper-circle-${key}`}
                className={currentNumber >= number ? STEPPER_ACTIVE_COLOR : STEPPER_INACTIVE_COLOR}
                style={{
                    ...(currentNumber === number ? STEPPER_ACTIVE_SIZE : STEPPER_INACTIVE_SIZE),
                    borderWidth: '2px',
                    borderColor: STEPPER_BORDER_COLOR,
                    borderStyle: 'solid',
                }}
            >
                {currentNumber === number && <p className="text-center text-light">{number}</p>}
            </div>
        )
    }

    const renderStepper = () => {
        const stepperUiElements = []
        for (let index = 0; index < props.maxSteps; index++) {
            stepperUiElements.push(circleWithNumber(index + 1, props.currentStep, index))
            if (index + 1 < props.maxSteps) {
                stepperUiElements.push(line(index))
            }
        }
        return stepperUiElements
    }

    return <div className="row">{renderStepper()}</div>
}
