import { useRef, useEffect, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { VideoPanelSettings } from '../../../types/Panel'
import Player = videojs.Player
import createVideoSync, { VideoSync, VideoSyncStatus } from '../../../services/VideoSyncService'
import CloudApi from '../../../api/CloudApi'
import { PlaybackState } from '../../../api/BrokerApi/types'

interface VideoPlayerProps {
    onVideoLoadedCallback: Function
    videoSettings: VideoPanelSettings
    videoSourceType: string
    playbackState: PlaybackState | undefined
    videoStatusCallback: (status: VideoSyncStatus) => void
    syncModeEnabled: boolean
    videoOffsetSeconds: number | undefined
    desiredVideoOffsetSecondsSlider: number
    setDesiredVideoOffsetSecondsSlider: (offset: number) => void
    soundMuted: boolean
}

export const VideoPlayer = (props: VideoPlayerProps) => {
    const videoRef = useRef<HTMLDivElement>(null)
    const playerRef = useRef<Player>()
    const videoSyncRef = useRef<VideoSync>()
    const [currentRecording, setCurrentRecording] = useState({
        offsetInSeconds: 0,
    })
    const [videoSourceUrl, setVideoSourceUrl] = useState<string>()
    const [error, setError] = useState<boolean>()

    useEffect(() => {
        // This re-fetch the video download url each time the panel is added so we can support that a panel is re-added although
        // the original url has expired
        CloudApi.getMediaFile(
            props.videoSettings.projectId,
            props.videoSettings.recordingSessionId,
            props.videoSettings.file
        )
            .then((res) => setVideoSourceUrl(res.data.downloadUrl))
            .catch(() => setError(true))
    }, [])

    useEffect(() => {
        if (playerRef?.current) {
            playerRef.current?.muted(props.soundMuted)
        }
    }, [props.soundMuted])

    const defaultOptions = {
        autoplay: false,
        controls: false,
        responsive: true,
        fluid: true,
    }

    useEffect(() => {
        if (!videoSourceUrl) {
            return
        }

        const options = {
            ...defaultOptions,
            sources: [
                {
                    src: videoSourceUrl,
                },
            ],
        }

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement('video-js')
            videoElement.classList.add('vjs-big-play-centered')
            videoRef.current?.appendChild(videoElement)
            playerRef.current = videojs(videoElement, options, () => {
                props.onVideoLoadedCallback()
            })
            playerRef.current.fill(true)
            playerRef.current.playsinline(true)
            playerRef.current.muted(props.soundMuted)

            // Debug things to try
            const maxDriftTimeSeconds = localStorage.getItem('maxDriftTimeSeconds')
            const alignmentInterval = localStorage.getItem('alignmentInterval')
            const videoSyncEnabled = localStorage.getItem('videoSyncEnabled')
            const videoSync = createVideoSync(
                playerRef.current!,
                currentRecording,
                props.videoOffsetSeconds,
                alignmentInterval != null ? parseInt(alignmentInterval) : 3000,
                maxDriftTimeSeconds != null ? parseFloat(maxDriftTimeSeconds) : 0.5,
                !(videoSyncEnabled != null && videoSyncEnabled === 'no')
            )
            videoSyncRef.current = videoSync
        } else {
            playerRef.current.src(options.sources)
        }
    }, [videoSourceUrl])

    const [listenersAdded, setListenersAdded] = useState(false)

    useEffect(() => {
        if (!listenersAdded && playerRef.current) {
            // Add listeners
            setListenersAdded(true)
        }
    }, [listenersAdded, playerRef.current])

    useEffect(() => {
        if (!playerRef.current) return // Exit if playerRef.current is not yet initialized

        const player = playerRef.current

        // Ensure listeners are set up only once
        const setupListeners = () => {
            // Add other event listeners
            player.on('timeupdate', () => {
                if (props.syncModeEnabled && props.playbackState) {
                    props.setDesiredVideoOffsetSecondsSlider(props.playbackState.currentOffsetDurationMs() / 1000 - player.currentTime())
                }
            })
        }

        setupListeners()

        return () => {
            console.log('Cleaning up listeners')
            player.off('timeupdate')
            player.getChild('controlBar')?.getChild('progressControl')?.getChild('seekBar')?.off()
        }
    }, [playerRef.current, props.syncModeEnabled, props.setDesiredVideoOffsetSecondsSlider])

    useEffect(() => {
        if (props.syncModeEnabled) {
            return
        }

        if (props.playbackState) {
            currentRecording.offsetInSeconds = props.playbackState.currentOffsetDurationMs() / 1000
        }

        if (props.playbackState && playerRef.current) {
            if (props.playbackState.isPlaying()) {
                videoSyncRef.current?.play()
            } else if (props.playbackState.isStopped()) {
                videoSyncRef.current?.stop()
            } else if (props.playbackState.isPaused()) {
                videoSyncRef.current?.pause()
            }
        }

        if (videoSyncRef.current) {
            props.videoStatusCallback(videoSyncRef.current.status())
        }
    }, [props.playbackState])

    useEffect(() => {
        if (playerRef.current !== undefined) {
            playerRef.current.controls(props.syncModeEnabled)
            videoSyncRef.current?.enable(!props.syncModeEnabled)
            if (!props.syncModeEnabled && props.playbackState) {
                const secondsElapsedInRecording = props.playbackState.currentOffsetDurationMs() / 1000
                const newOffset = secondsElapsedInRecording - videoSyncRef.current!.status().videoCurrentTimeSecs
                props.setDesiredVideoOffsetSecondsSlider(newOffset)
            }
        }
    }, [props.syncModeEnabled])

    useEffect(() => {
        console.log('Video offset changed ' + props.videoOffsetSeconds)
        if (props.videoOffsetSeconds && props.playbackState) {
            videoSyncRef.current?.setVideoOffsetSeconds(props.videoOffsetSeconds)
        }
    }, [props.videoOffsetSeconds])

    return (
        <>
            <div className={'h-100'}>
                <div data-vjs-player className={'h-100'}>
                    {error === true && <p>Failed to load video. Please remove this panel and add it again.</p>}
                    {!error && <div className={'h-100'} ref={videoRef} />}
                </div>
            </div>
        </>
    )
}

export default VideoPlayer
